import { Box, Button, Card, FormControl, IconButton, TextField, Typography, useTheme } from '@mui/material'
import { Form, Formik, ErrorMessage, Field } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import { useAuthStore } from '../store/auth.store'
import bgImg from '../assets/young-student-min.jpg'
import aliveLogo from '../assets/alivelogo.png'
// import bgPattern from '../assets/bg-pattern1.png'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { getBrowserDetails, isMobileDevice } from '../utils/utilities'
// import { Axios } from '../utils/utilities'

const AliveLogin = () => {

    const AuthStore = useAuthStore()

    const navigate = useNavigate()

    const { palette } = useTheme()

    const [passwordVisibility, setPasswordVisibility] = useState( false )
    const [isLoggingIn, setIsLoggingIn] = useState( false )

    const [loginType, setLoginType] = useState( window.localStorage.getItem( 'aliveErpLoginType' ) ? window.localStorage.getItem( 'aliveErpLoginType' ) : 'STUDENT' )
    const [searchParams] = useSearchParams( { redirect: null } )
    const redirect = searchParams.get( 'redirect' ) || "null"

    const initialValues = {
        email: '',
        password: ''
    }

    const toggleVisibility = () => {
        setPasswordVisibility( !passwordVisibility )
    }

    const validationSchema = Yup.object().shape( {
        email: Yup.string().required( "This field is required!" ),
        password: Yup.string().required( "Password field is required!" )
    } )

    const login = async ( values ) => {
        setIsLoggingIn( true )
        const aliveRes = await AuthStore.doAuthViaERP( { username: values.email, password: values.password, usertype: loginType, device: isMobileDevice().isMobile ? "mobile" : "desktop", browser_info: getBrowserDetails() } )
        if ( aliveRes ) {

            const redirectValue = decodeURIComponent( redirect )

            if ( loginType === "STUDENT" )
                navigate( redirectValue && redirectValue !== "null" ? redirectValue : '/student/home', { replace: true } )
            else
                navigate( redirectValue && redirectValue !== "null" ? redirectValue : '/faculty/home', { replace: true } )

        }
        setIsLoggingIn( false )
    }

    return (

        <Box color="primary.main" display="flex" height="100%" alignItems="center" width="100%" justifyContent="center" flexWrap="wrap" sx={{ overflowX: "hidden", backgroundImage: "url('/imgs/bg-pattern1.png')", backgroundRepeat: "repeat" }}>
            <Card sx={{ overflow: "auto", height: { md: "80% !important", xs: "100% !important" }, width: { md: "900px !important", xs: "100% !important" }, minHeight: "615px", borderRadius: { sm: "5px !important", xs: "0 !important" }, alignItems: "center", display: "grid", gridTemplateColumns: { md: "50% 50% !important", xs: "0 100% !important" } }}>
                <Box height="100%" width="100%" display="flex" flexWrap="wrap" overflow="hidden">
                    <img height="100%" width="100%" style={{ borderRadius: "5px 0 0 5px", objectFit: "cover" }} src={bgImg} alt="Login Background" />
                </Box>
                <Box overflow="auto" height="100%" sx={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>

                    <Box margin="20px" display="flex" flexDirection="column" alignItems="center" gap="10px" borderRadius="5px" padding="10px" bgcolor={palette.errorMessage.main}>
                        <Typography textAlign="center" color={palette.errorMessage.contrastText} variant='h6'>Decommissioning of ALIVE</Typography>
                        <Typography textAlign="center" color={palette.errorMessage.contrastText} variant='body2' fontSize="12px">We want to inform you that the current Alive platform will be decommissioned shortly and will be completely removed.</Typography>
                        <Typography textAlign="center" width="fit-content" bgcolor={palette.errorMessage.contrastText} borderRadius="5px" padding="10px 20px" fontWeight="700" color={palette.errorMessage.main} variant='body2' fontSize="12px"><Link className='default-link' to={`https://www.acharyaerptech.in/Login`}>Please Login here</Link></Typography>
                    </Box>

                    <Box display="flex" marginBottom="30px" gap="10px" alignItems="center" justifyContent={{ md: "left", xs: "center" }}>
                        <Box width="fit-content" position="relative" sx={{ "&::after": { content: '""', position: "absolute", bottom: 0, left: loginType === 'STUDENT' ? 0 : "50%", height: "3px", width: "50%", background: "#f18f08", transition: "0.5s ease" } }}>
                            <Button onClick={() => { setLoginType( 'STUDENT' ); window.localStorage.setItem( 'aliveErpLoginType', 'STUDENT' ) }} sx={{ color: "primary.main", padding: "5px 10px" }} >Student</Button>
                            <Button onClick={() => { setLoginType( 'FACULTY' ); window.localStorage.setItem( 'aliveErpLoginType', 'FACULTY' ) }} sx={{ color: "primary.main", padding: "5px 10px" }} >Faculty</Button>
                        </Box>
                    </Box>
                    <img src={aliveLogo} style={{ marginLeft: "-10px", marginBottom: "10px" }} width="100px" alt="Alive Logo" />
                    <Typography variant='h4' component="h1" textAlign="center" gutterBottom>Welcome Back!</Typography>
                    <Typography variant='body2' gutterBottom >Please login to your account.</Typography>
                    <Formik initialValues={initialValues} onSubmit={login} validationSchema={validationSchema}>
                        {( props ) => (
                            <Form method='post' autoComplete='off' style={{ margin: "20px 0", display: "flex", flexDirection: "column", gap: "10px", width: "100%", alignItems: "center" }}>
                                <FormControl sx={{ width: { md: "320px", xs: "100%" }, maxWidth: "450px", marginBottom: "10px" }} >
                                    <Typography gutterBottom variant='subtitle2' marginLeft="5px" color={palette.labelColor}>{loginType === 'student' ? "AUID *" : "User name *"}</Typography>
                                    <Field as={TextField} InputLabelProps={{ sx: { color: "primary.main !important", fontSize: "14px" } }} sx={{ borderRadius: "2px", outline: 'none', width: { sm: "100%", xs: "95% !important" }, margin: { sm: "initial", xs: "auto !important" }, "&:focus": { borderLeft: "5px solid #f9b17a", } }} type="text" name='email' variant='outlined' />
                                    <Typography color="error.dark" variant='subtitle2' fontSize="12px" marginLeft="5px" ><ErrorMessage name='email' /></Typography>
                                </FormControl>
                                <FormControl sx={{ width: { md: "320px", xs: "100%" }, maxWidth: "450px", marginBottom: "10px" }} >
                                    <Typography gutterBottom variant='subtitle2' marginLeft="5px" color={palette.labelColor}>Password</Typography>
                                    <Field as={TextField} type={passwordVisibility ? "text" : "password"} InputLabelProps={{ sx: { color: "primary.main !important", fontSize: "14px" } }} sx={{ borderRadius: "2px", outline: 'none', width: { sm: "100%", xs: "95% !important" }, margin: { sm: "initial", xs: "auto !important" }, "&:focus": { borderLeft: "5px solid #f9b17a", } }} name='password' variant='outlined' InputProps={{ endAdornment: <IconButton onClick={toggleVisibility}>{passwordVisibility ? <VisibilityOff /> : <Visibility />}</IconButton> }} />
                                    <Typography color="error.dark" variant='subtitle2' fontSize="12px" marginLeft="5px" ><ErrorMessage name='password' /></Typography>
                                </FormControl>
                                <FormControl sx={{ width: { md: "320px", xs: "100%" }, maxWidth: "450px", marginBottom: "10px" }}>
                                    <Box display="flex" justifyContent="center">
                                        <Button sx={{ textTransform: "capitalize", width: { sm: "100%", xs: "95% !important" }, height: "53px" }} disableElevation type='submit' color='primary' variant='contained' disabled={isLoggingIn}>{isLoggingIn ? "Logging in..." : "Login"}</Button>
                                    </Box>
                                </FormControl>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box >

    )
}

export default AliveLogin
