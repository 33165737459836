import { Box, Button, CircularProgress, FormControl, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Icons, secondsToHMS } from '../../../../utils/utilities'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import { useQuizAndQuestionbankStore } from '../../../../store/quiz.store'
import { useUserStore } from '../../../../store/user.store'
import DataLoadingSpinner from '../../../common/DataLoadingSpinner'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'

const textButtonStyles = {
    marginTop: "2px",
    cursor: "pointer",
    position: "relative",
    '&::after': {
        content: `''`,
        display: "block",
        position: "absolute",
        top: "calc(100% - 4px)",
        left: "0",
        width: "0%",
        height: "1px",
        transition: "width 200ms",
        backgroundColor: "secondary.main"
    },
    '&:hover::after': {
        width: "100%",
    }
}


const ReplicateQuizForm = observer( ( { fetchQuizzesInstances, room, setAddMode, handleClose } ) => {

    const [error, setError] = useState( null )
    const [loading, setLoading] = useState( true )
    const [creating, setCreating] = useState( false )

    const QuizStore = useQuizAndQuestionbankStore()
    const UserStore = useUserStore()
    const { palette, border } = useTheme()

    const initialValues = {
        quiz_id: '-',
        start_time: room.sTime,
        end_time: room.eTime,
        access_group: room.batch_short_name !== "NA" ?
            { course_name_short: room.course_name_short, current_sem: room.current_sem, current_year: room.current_year, id: room.batch_assignment_id, batch_short_name: room.batch_short_name, batch_assignment_id: room.batch_assignment_id, branch_name: room.branch_name, branch_id: room.branch_id } :
            { course_branch_short_name: room.course_branch_short_name, current_sem: room.current_sem, current_year: room.current_year, id: `${room.section_assignment_id}-${room.section_short_name}`, section_assignment_id: room.section_assignment_id, section_short_name: room.section_short_name, branch_name: room.branch_name, branch_id: room.branch_id },
        start_date: new Date( room.selected_date ),
        room_id: room.time_table_id,
        quiz_duration: ''
    }

    const validationSchema = Yup.object().shape( {
        quiz_id: Yup.number().required( "Quiz is required" ).notOneOf( ["-"], "Please select a quiz" ),
        start_time: Yup.string().required( "Quiz start time is required" ).test( {
            message: "Please select valid meeting start date and time", name: "future_time", test: ( val, { parent } ) => {
                try {
                    const dateTime = new Date( parent.date ).toISOString().split( "T" )[0] + "T" + val
                    if ( new Date( dateTime ) > new Date() )
                        return true
                    return false
                } catch ( err ) {
                    return true
                }
            }
        } ),
        end_time: Yup.string().required( "Quiz end time is required" ).test( {
            message: "Please select valid end time", name: "valid_end_time", test: ( val, { parent } ) => {
                try {
                    const refDate = new Date().toISOString().split( "T" )[0]
                    const endTime = refDate + "T" + val
                    const startTime = refDate + "T" + parent.start_time
                    if ( !parent.start_time ) return false
                    if ( new Date( startTime ) < new Date( endTime ) )
                        return true
                    return false
                } catch ( err ) {
                    return true
                }
            }
        } ),
        quiz_duration: Yup.string().test( "CHECK_FOR_QUIZ_DURATION", "Please enter a valid duration. (format: HH:MM)", ( val, { parent } ) => {
            const splitted = val?.split( ":" )
            if ( !val ) return true
            if ( val && ( !val.match( /^\d\d:\d\d$/ ) || +splitted[1] > 60 || +splitted[0] >= 24 ) )
                return false
            return true
        } ).test( "CHECK_FOR_CORRECT_QUIZ_DURATION", "Invalid duration or duration exceeds the set start and end time window", ( val, { parent } ) => {
            if ( val ) {
                const splitted = val?.split( ":" )
                const dateObject = new Date( parent.start_date )
                const dateStr = `${dateObject.getFullYear()}-${dateObject.getMonth() + 1}-${dateObject.getDate()}`
                const selectedDuration = ( new Date( `${dateStr} ${parent.end_time}` ).getTime() - new Date( `${dateStr} ${parent.start_time}` ).getTime() ) / 1000
                if ( val && ( !val.match( /^\d\d:\d\d$/ ) || +splitted[1] > 60 ) ) {
                    return false
                } else {
                    if ( val === "00:00" )
                        return false
                    const timeInSeconds = ( +splitted[0] * 60 * 60 ) + splitted[1] * 60
                    if ( typeof selectedDuration === 'number' && selectedDuration >= timeInSeconds ) {
                        return true
                    }
                    else return false
                }
            } else
                return true
        }
        )
    } )

    const handleTimeChange = ( name, val, setValue ) => {
        const time = new Date( val ).toLocaleTimeString( 'en-IN', { hourCycle: "h24", hour: "2-digit", minute: "2-digit", } )
        setValue( name, time )
    }

    const gotoCreateMode = async () => {
        // await UserStore.fetchUserSubjects()
        // setCreateMode( true )
        setAddMode( true )
    }

    const addQuiz = async ( values, { resetForm } ) => {
        setCreating( true )
        const success = await QuizStore.createQuizInstance( values.quiz_id, values )
        console.log( success )

        if ( success ) {
            handleClose( true, success.data || {} )
            resetForm()
            toast( `New Quiz created successfully` )
        }
        setCreating( false )
    }

    const getData = useCallback( async () => {
        setLoading( true )
        const gotQuizzes = await QuizStore.fetchQuizes( {} )
        if ( UserStore.getErrorsStatuses?.subjects === true )
            await UserStore.fetchUserSubjects()
        if ( !( ( !UserStore.getErrorsStatuses?.subjects || UserStore.getUserSubjects?.length > 0 ) && gotQuizzes ) ) {
            setError( true )
        } else {
            setError( false )
        }
        setLoading( false )
    }, [QuizStore, UserStore] )

    useEffect( () => {
        getData()
    }, [getData] )

    return (
        <Formik onSubmit={addQuiz} initialValues={initialValues} validationSchema={validationSchema} >
            {( { values, setFieldValue, resetForm } ) => {

                let maxDuration
                if ( values.end_time && values.start_time ) {
                    const dateObject = new Date( values.start_date )
                    const dateStr = `${dateObject.getFullYear()}-${dateObject.getMonth() + 1}-${dateObject.getDate()}`
                    const selectedDuration = ( new Date( `${dateStr} ${values.end_time}` ).getTime() - new Date( `${dateStr} ${values.start_time}` ).getTime() ) / 1000
                    maxDuration = secondsToHMS( selectedDuration )?.substring( 0, 5 )
                }

                return (
                    <Form>

                        <Box margin="20px" marginTop="0" border={border[1]} bgcolor={palette.form.formBg} borderRadius="5px" padding="20px">

                            <FormControl fullWidth>
                                <Typography variant="subtitle2" gutterBottom color={palette.labelColor}>What would you like to name this quiz? *</Typography>
                                <Field size="small" as={Select} sx={{ color: values.quiz_id === "-" && palette.form.placeholder }} name="quiz_id" >
                                    <MenuItem sx={{ fontSize: "14px" }} dense disabled value="-">{loading ? <DataLoadingSpinner color="secondary" waitingMessage="Loading quizzes..." size={14} padding='0' /> : "Select an existing quiz"}</MenuItem>
                                    {!loading && QuizStore.getQuizes.filter( q => q?.subject?.subject_id === room.subject_id ).length > 0 && QuizStore.getQuizes?.filter( q => q?.subject?.subject_id === room.subject_id )?.map( quiz => (
                                        <MenuItem dense sx={{ fontSize: "14px" }} key={quiz.id} value={quiz.id}>{quiz.title}</MenuItem>
                                    ) )}
                                </Field>
                                {!loading && !error && <Box fontWeight="500" fontSize="12px" display="flex" alignItems="center" gap="5px" color="textSecondary">
                                    Quiz not up there?
                                    <Typography fontSize="12px" variant='subtitle2' display="inline-flex" alignItems="center" gap="5px" onClick={gotoCreateMode} color="secondary" sx={textButtonStyles}>
                                        Create new quiz
                                    </Typography>
                                </Box>}
                                {error && <Box fontWeight="500" fontSize="12px" display="flex" alignItems="center" gap="5px" >
                                    Error occured while fetching quizzes
                                    <Typography fontSize="12px" variant='subtitle2' display="inline-flex" alignItems="center" gap="5px" onClick={getData} color="error.main" sx={textButtonStyles}>
                                        Retry again {loading && <CircularProgress size={12} />}
                                    </Typography>
                                </Box>}
                            </FormControl>

                            <FormControl fullWidth margin="normal">
                                <Typography variant="subtitle2" gutterBottom color={palette.labelColor}>On what date should the quiz be scheduled? *</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disabled
                                        sx={{ "width": "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                        disableToolbar
                                        variant="inline"
                                        format="DD-MM-YYYY"
                                        value={dayjs( values.start_date )}
                                        onChange={( val ) => setFieldValue( 'start_date', val )}
                                        readOnly
                                    />
                                </LocalizationProvider>
                            </FormControl>
                            <Box display="flex" flexWrap="wrap" gap="10px" alignItems="flex-start">
                                <FormControl margin="normal">
                                    <Typography variant="subtitle2" gutterBottom color={palette.labelColor}>Start time</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            sx={{ "width": "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                            disableToolbar
                                            defaultValue={dayjs( new Date( `2024-01-01 ${room.sTime}` ) )}
                                            onChange={val => handleTimeChange( 'start_time', val, setFieldValue )}
                                        />
                                    </LocalizationProvider>
                                    <Typography variant="body2" gutterBottom color="errorMessage.main"><ErrorMessage name='start_time' /></Typography>
                                </FormControl>

                                <FormControl margin="normal">
                                    <Typography variant="subtitle2" gutterBottom color={palette.labelColor}>End time</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            sx={{ "width": "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                            defaultValue={dayjs( new Date( `2024-01-01 ${room.eTime}` ) )}
                                            onChange={val => handleTimeChange( 'end_time', val, setFieldValue )}
                                        />
                                    </LocalizationProvider>
                                    <Typography variant="body2" gutterBottom color="errorMessage.main"><ErrorMessage name='end_time' /></Typography>
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <Typography variant="subtitle2" gutterBottom color={palette.labelColor}>Quiz duration {values.end_time && values.start_time && `(Max: ${maxDuration})`} </Typography>
                                    <Field size="small" as={TextField} placeholder="For 2 hrs quiz set value as 02:00" type="text" name="quiz_duration" />
                                    <Typography fontSize="12px" color='error'><ErrorMessage name='quiz_duration' /></Typography>
                                </FormControl>
                            </Box>

                            <Box alignItems="center" gap="20px" marginTop="40px" flexWrap="wrap" display="flex">
                                <Button type="submit" disabled={creating} startIcon={creating ? <CircularProgress size={14} /> : Icons.default.AddIcon} variant="contained" disableElevation sx={{ textTransform: "capitalize" }} >{creating ? "Adding quiz instance" : "Add quiz"}</Button>
                                <Button onClick={handleClose} disabled={creating} variant="contained" disableElevation color="error" sx={{ textTransform: "capitalize" }} >Cancel</Button>
                            </Box>

                        </Box>
                    </Form>
                )
            }}
        </Formik>
    )
} )

export default ReplicateQuizForm
